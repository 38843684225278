export class SystemConfig {
  dashboard: boolean | undefined;
  feed: boolean | undefined;
  portfolio: boolean | undefined;
  analyze: boolean | undefined;
  market: boolean | undefined;
  socialize: boolean | undefined;
  articles_overview: boolean | undefined;
  settings: boolean | undefined;
  notication: boolean | undefined;
  header: boolean | undefined;
  sidemenu: boolean | undefined;
  reportaBug: boolean | undefined;
  headerSearch: boolean | undefined;
  createPost: boolean | undefined;
}
