<template>
  <div class="viewPlaybookCardDv" v-if="stock">
    <IconComponent
      class="newiconDv"
      @click.stop="onRemove()"
      @touchstart="onRemove()"
      :id="`minusWining${index}`"
      v-if="showRemove"
      icon="minus.svg"
    />
    <div class="playbookinnerCardTag">
      <IconComponent
        class="cardTagcontainer"
        v-if="stock.traderType === 'long'"
        icon="icon-playbook-long.svg"
      />
      <IconComponent
        class="cardTagcontainer"
        v-if="stock.traderType === 'short'"
        icon="icon-playbook-short.svg"
      />
      <h2>{{ stock.traderType }}</h2>
    </div>
    <div class="iteminfoDv firstinfoDv">
      <StockImageTag
        :stockId="stock.stockId"
        :imgUrl="stock.logoUrl"
        :symbol="stock.symbol"
        :market="stock.type"
      />
      <div class="textDv">
        <h1>{{ stock.symbol }}</h1>
        <el-tooltip
          class="box-item"
          effect="dark"
          :content="stock.name"
          placement="top-start"
        >
          <p>{{ stock.name }}</p>
        </el-tooltip>
      </div>
    </div>
    <div class="iteminfoDv secondinfoDv">
      <div class="restoreInfoDv tockactualprice">
        <IconComponent
          class="iconinfoDv"
          icon="icon-playbook-stockactualprice.svg"
        />
        <h3
          ref="priceElement"
          v-html="
            $filters.systemNumberConvention(
              stock.entry,
              true,
              false,
              widthCalculating
            )
          "
        ></h3>
      </div>
      <div class="restoreInfoDv stockstopprice">
        <IconComponent
          class="iconinfoDv"
          icon="icon-playbook-stockstopprice.svg"
        />
        <h3
          ref="priceElement"
          v-html="
            $filters.systemNumberConvention(
              stock.stopLoss,
              true,
              false,
              widthCalculating
            )
          "
        ></h3>
      </div>
    </div>
    <div class="iteminfoDv threeinfoDv">
      <div class="restoreInfoDv stocktargetoneprice">
        <IconComponent
          class="iconinfoDv"
          icon="icon-playbook-stocktargetoneprice.svg"
        />
        <h3
          ref="priceElement"
          v-html="
            $filters.systemNumberConvention(
              stock.targetOne,
              true,
              false,
              widthCalculating
            )
          "
        ></h3>
      </div>
      <div class="restoreInfoDv stocktargettwoprice">
        <IconComponent
          class="iconinfoDv"
          icon="icon-playbook-stocktargettwoprice.svg"
          v-if="stock.traderType === 'long'"
        />
        <IconComponent
          class="iconinfoDv"
          icon="icon-playbook-stocktargettwopriceshort.svg"
          v-if="stock.traderType === 'short'"
        />
        <h3
          ref="priceElement"
          v-html="
            $filters.systemNumberConvention(
              stock.targetTwo,
              true,
              false,
              widthCalculating
            )
          "
        ></h3>
      </div>
    </div>
    <div class="iteminfoDv fourthinfoDv">
      <el-popover
        trigger="hover"
        popper-class="cardStyled dark"
        class="cardStyled dark"
        popper-style="
          background:var(--modal-background-color);
           box-shadow: 0px 0px 25px #191527 !important;
                     border: 0px solid var(--el-border-color-light) !important;
          padding: 0px;border-radius:8px;height: auto;width:250px"
      >
        <template #reference>
          <IconComponent
            class="iconinfoDv"
            icon="icon-playbook-stocknotes.svg"
          />
        </template>
        <template #default>
          <div class="viewPlayBookPopoverDv">
            <p v-if="stock.notes">{{ stock.notes }}</p>
            <p v-if="!stock.notes" style="text-align: center">
              No Notes Found!
            </p>
            <div class="footerviewInfoDv" v-if="user && stock.notes">
              <div class="firstDv">
                <ProfileAvatarComponent
                  :key="images.profilePicture"
                  :profileImage="images.profilePicture"
                  :border="true"
                  :badges="false"
                  class="small extraProfileDv"
                />
                <h2>{{ user.fullName }}</h2>
              </div>
              <div class="endDv">
                <h2>2 hrs ago</h2>
              </div>
            </div>
          </div>
        </template>
      </el-popover>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import IconComponent from "@/components/IconComponent.vue";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import ProfileAvatarComponent from "@/components/ProfileAvatarComponent.vue";

export default defineComponent({
  props: {
    stock: Object,
    showRemove: Boolean,
  },
  components: {
    IconComponent,
    ProfileAvatarComponent,
  },
  emits: ["onRemove"],
  setup(props, context) {
    const user = ref<any>({});
    const images = ref<any>({
      profilePicture: "",
      coverPhoto: "",
    });
    const onRemove = () => context.emit("onRemove");

    const priceElement = ref<HTMLElement | null>(null);
    const widthCalculating = ref();

    onMounted(() => {
      user.value = storageService.getUser();
      images.value.profilePicture = utilService.getUserProfilePicture(
        user.value?._id!,
        user.value?.profilePicture!,
        "male",
        false,
        false
      );
      if (priceElement.value) {
        const width = priceElement.value.offsetWidth;
        widthCalculating.value = width;
      }
    });

    watch(priceElement, () => {
      if (priceElement.value) {
        const width = priceElement.value.offsetWidth;
        widthCalculating.value = width;
      }
    });

    return {
      onRemove,
      user,
      images,
      priceElement,
      widthCalculating,
    };
  },
});
</script>

<style lang="scss">
.viewPlaybookCardDv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: var(--card-tile-color);
  box-shadow: 0px 0px 10px var(--color-new-darg-grey);
  border-radius: 10px;
  padding: 15px;
  height: 80px;
  gap: 10px;
  width: 390px;
  .newiconDv {
    width: 18px;
    height: auto;
    z-index: 999;
    right: -6px;
    position: absolute;
    top: -6px;
  }
  .playbookinnerCardTag {
    background: var(--color-mid-purple-gray);
    border-radius: 10px 0px 0px 10px;
    height: 80px;
    width: 22px;
    padding: 0px 2px 0px 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    left: 0;
    flex-direction: column;
    h2 {
      transform: rotate(-90deg);
      font-size: var(--font-xs);
      line-height: 1;
      letter-spacing: 0px;
      margin: 10px 0px 0px 0px;
      padding: 0px;
      font-weight: 400;
      text-transform: capitalize;
    }
    .cardTagcontainer {
      width: 12px;
      height: 12px;
      margin-top: -10px;
    }
  }
  .iteminfoDv {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    &.firstinfoDv {
      flex-direction: row;
      width: calc(39% - 10px);
      max-width: calc(39% - 10px);
      flex: 0 0 calc(39% - 10px);
      overflow: hidden;
      margin-left: 28px;
      .textDv {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        overflow: hidden;
        margin-left: 10px;
        h1 {
          @include stockSymbol();
          text-align: start;
          margin: 3px 0px 0px;
          width: 100%;
        }
        p {
          font-size: var(--font-sm);
          letter-spacing: 0px;
          margin: 3px 0px 0px;
          padding: 0px;
          color: var(--text-color);
          opacity: 0.5;
          width: 100%;
          font-weight: 400;
          @include truncate();
        }
      }
    }
    &.secondinfoDv {
      width: calc(25% - 10px);
      max-width: calc(25% - 10px);
      flex: 0 0 calc(25% - 10px);
    }
    &.threeinfoDv {
      width: calc(22% - 10px);
      max-width: calc(22% - 10px);
      flex: 0 0 calc(24% - 10px);
    }
    &.fourthinfoDv {
      width: calc(10% - 10px);
      max-width: calc(10% - 10px);
      flex: 0 0 calc(10% - 10px);
    }
    .restoreInfoDv {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
      width: 100%;
      h3 {
        text-align: start;
        width: 100%;
        font-size: var(--font-xs);
        line-height: 1;
        letter-spacing: 0px;
        margin: 0px;
        padding: 0px;
        font-weight: 400;
      }
      .iconinfoDv {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
      }
      &.stockstopprice {
        margin: 3px 0px 0px;
      }
      &.stocktargettwoprice {
        margin: 3px 0px 0px;
      }
    }
  }
  &.longActive {
    .playbookinnerCardTag {
      h2 {
        color: var(--recommend-bullish);
        margin: 10px 0px 0px -2px;
      }
    }
    .iteminfoDv {
      .restoreInfoDv {
        &.stockactualprice {
          h3 {
            color: var(--text-color);
          }
        }
        &.stockstopprice {
          h3 {
            color: var(--red);
          }
        }
        &.stocktargetoneprice {
          h3 {
            color: var(--color-new-light-gren);
          }
        }
        &.stocktargettwoprice {
          h3 {
            color: var(--recommend-bullish);
          }
        }
      }
    }
  }
  &.shortActive {
    .playbookinnerCardTag {
      background: var(--color-mid-purple-gray);
      h2 {
        color: var(--red);
      }
    }
    .iteminfoDv {
      .restoreInfoDv {
        &.stockactualprice {
          h3 {
            color: var(--modal-background-color-alt);
          }
        }
        &.stockstopprice {
          h3 {
            color: var(--red);
          }
        }
        &.stocktargetoneprice {
          h3 {
            color: var(--orange);
          }
        }
        &.stocktargettwoprice {
          h3 {
            color: var(--red);
          }
        }
      }
    }
  }
}
</style>
