export class ConfigurationModel {
  activitiesCount: number = 3;
  widgetsCount: number = 1;
  maxWidth: string = "";
  activitiesWidth: string = "";
  widgetsWidth: string = "";
  totalColumns: number = 4;
  showWidgets: Boolean = false;
  showTimeline: Boolean = true;

  constructor(
    activityCt: number = 3,
    widgetsCt: number = 1,
    maxWidth: string = "600px",
    totalColumns: number = 4,
    showWidgets: Boolean = false,
    showTimeline: Boolean = true
  ) {
    this.activitiesCount = activityCt;
    this.widgetsCount = widgetsCt;
    this.maxWidth = maxWidth;
    this.activitiesWidth = "100%";
    this.widgetsWidth = "100%";
    this.totalColumns = totalColumns;
    this.showWidgets = showWidgets;
    this.showTimeline = showTimeline;
  }

  get totalCount(): number {
    return this.activitiesCount + this.widgetsCount;
  }
}
