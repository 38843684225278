import storageService from "@/services/storage.service";
import axiosInstance from "./api";
import firebaseService from "@/services/firebase.service";

const setup = (router: any) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.headers === undefined) {
        config.headers = {};
      }
      const token = storageService.getToken();
      const sessionId = storageService.getSessionId();
      const user = storageService.getUser();
      const isFirebaseLogin = storageService.getIsFirebaseLogin();
      if (token) {
        config.headers["Authorization"] =
          (isFirebaseLogin ? "Firebase " : "Bearer ") + token; // for Spring Boot back-end
      }
      if (user && user._id) {
        config.headers["UserId"] = user._id;
      }

      const currentDate = new Date();
      const utcOffsetMinutes = currentDate.getTimezoneOffset();
      const utcOffsetHours = utcOffsetMinutes / 60;
      config.headers["offsettimezone"] = utcOffsetHours.toString();
      config.headers["NewRoute"] = storageService.GetProperty("newRoute");
      config.headers["OldRoute"] = storageService.GetProperty("oldRoute");
      config.headers["sessionid"] = sessionId
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;

      if (!originalConfig.url.includes('auth') && err.response) {
        if (err.response.status == 401 && originalConfig) {
          storageService.RemoveAllProperties();
          location.reload();
          // await firebaseService.getAuthToken();
          // err.config.__isRetryRequest = true;
          // let firebaseToken = storageService.getToken();
          // err.config.headers.token = `Firebase ${firebaseToken}`;
          // return axiosInstance(err.config);
        }
      }

    }
  );
};

export default setup;
