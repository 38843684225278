<template>
    <section class="QRCodeMainFrmDv QRCodeMainFrmDvSpaceTop">
        <div class="headerDv headerInline">
            <div class="leftDv">
                <h2>Share QR Code</h2>
                <p>Instantly access information by scanning this QR code!</p>
            </div>
            <div class="rightDv">
                <!-- <div>
                    <IconComponent icon="icon-qr-code-export.svg" class="exportDv" />
                </div> -->
            </div>
        </div>
        <div class="bodyDv">
            <el-scrollbar class="bodyScrollDv">
                <ShareQR />
            </el-scrollbar>
        </div>
    </section>
</template>
  
<script lang="ts">
import IconComponent from "@/components/IconComponent.vue";
import {
    StockMarketColors,
    StockMarkets,
} from "@/helpers/models/stock/stocks.model";
import { Ref, defineComponent, onMounted, ref } from "vue";
import ShareQR from "@/views/private/QRCode/components/ShareQR.vue";
import { useRouter } from "vue-router";

export default defineComponent({
    components: {
        IconComponent,
        ShareQR,
    },
    setup() {
        var defaultPx = 14.28;
        var totalData: {
            title: string;
            count: number;
            color: string;
            maxWidth: number;
        }[] = [
                {
                    title: StockMarkets.stocks,
                    count: 0,
                    color: StockMarketColors.stocks,
                    maxWidth: defaultPx,
                },
                {
                    title: StockMarkets.etf,
                    count: 0,
                    color: StockMarketColors.etf,
                    maxWidth: defaultPx,
                },
                {
                    title: StockMarkets.Futures,
                    count: 0,
                    color: StockMarketColors.Futures,
                    maxWidth: defaultPx,
                },
                {
                    title: StockMarkets.crypto,
                    count: 0,
                    color: StockMarketColors.crypto,
                    maxWidth: defaultPx,
                },
                {
                    title: StockMarkets.nft,
                    count: 0,
                    color: StockMarketColors.nft,
                    maxWidth: defaultPx,
                },
                {
                    title: StockMarkets.indices,
                    count: 0,
                    color: StockMarketColors.indices,
                    maxWidth: defaultPx,
                },
                {
                    title: StockMarkets.options,
                    count: 0,
                    color: StockMarketColors.options,
                    maxWidth: defaultPx,
                },
            ];
        const changeSegment: Ref<string> = ref("");

        const changingTabs = (data: any) => {
            changeSegment.value = data;
        };

        onMounted(async () => {
            changeSegment.value = "Share";
        });

        const router = useRouter();

        const goBack = () => {
            router.back();
        };

        return {
            changeSegment,
            changingTabs,
            totalData,
            goBack,
        };
    },
});
</script>
<style lang="scss" scoped>
@import "./QRCode.scss";
</style>
  