import httpService from "@/services/http.service";
import { onMounted, ref } from "vue";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import storageService from "@/services/storage.service";
import { StorageKeys } from "@/helpers/enum";
import { AppEnvironments, Environment } from "@/environment";
import { NotificationComposables } from "@/components/notifications/notification.composables";
const { getNotifications, unregisterSubscribe } = NotificationComposables();
var swRegistration: any;
export const useFCMNotification = () => {
  const deviceId = ref<string>(
    storageService.GetProperty(StorageKeys.deviceId)
  );
  const fcmToken = ref<string>(
    storageService.GetProperty(StorageKeys.fcmToken)
  );

  const unregister = () => {
    unregisterSubscribe();
  };

  const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      var environment = "internal";
      if (Environment.currentEnvironment == AppEnvironments.production) {
        environment = "production";
      } else if (Environment.currentEnvironment == AppEnvironments.stage) {
        environment = "stage";
      } else if (Environment.currentEnvironment == AppEnvironments.dev) {
        environment = "dev";
      }
      navigator.serviceWorker
        .register(`/${environment}-fcm-sw.js`)
        .then((registration) => {
          swRegistration = registration;
        })
        .catch((error) => {});
    }
  };

  const registerForNotification = () => {
    return new Promise(async (resolve, reject) => {
      try {
        getNotifications();
        const messaging = getMessaging();
        var deviceId = getDeviceId();
        var token = await getToken(messaging, {
          serviceWorkerRegistration: swRegistration,
        });
        setTokenAndId(deviceId, token);
        resolve(true);
      } catch (e) {
        resolve(false);
      }
    });
  };

  const getDeviceId = () => {
    const userAgent = navigator.userAgent;
    const cookieEnabled = navigator.cookieEnabled;
    return `${userAgent}-${cookieEnabled}`;
  };

  const setTokenAndId = async (id: string, token: string) => {
    if (deviceId.value && fcmToken.value) {
      await unregisterDevice();
    }
    deviceId.value = id;
    fcmToken.value = token;
    registerDevice();
  };

  const registerDevice = async () => {
    try {
      await httpService.registerFCMNotification(deviceId.value, fcmToken.value);
      listenMessage();
    } catch (e) {}
  };

  const unregisterDevice = async () => {
    try {
      await httpService.unregisterFCMNotification(
        deviceId.value,
        fcmToken.value
      );
    } catch (e) {}
  };

  const listenMessage = () => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {});
  };

  return {
    deviceId,
    fcmToken,
    registerServiceWorker,
    registerForNotification,
    setTokenAndId,
    getDeviceId,
    unregister,
  };
};
