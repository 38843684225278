import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "svg_icon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    _createVNode(_component_inline_svg, {
      src: require('../assets/svg' + _ctx.path + '/' + _ctx.icon)
    }, null, 8, ["src"])
  ]))
}