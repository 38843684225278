import { ValidSortFilter } from "@/helpers/enum";
import { StockMarkets } from "@/helpers/models/stock/stocks.model";
import httpService from "@/services/http.service";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { debounce } from "lodash";
import { onBeforeUnmount, onMounted, ref } from "vue";

const segmentType = ref("all");
const filterType = ref(ValidSortFilter.diversification);
const filterName = ref("Diversification");
const markets = ref<string[]>([]);
const page = ref(1);
const count = ref(32);
const totalCount = ref(0);
const isDone = ref(false);
const isUsersLoading = ref<boolean>(false);
// const isUsersTwoLoading = ref<boolean>(false);
var usersList = ref<any[]>([]);
var filterList = ref<any[]>([]);
const tradersCount = ref<number>(0);
const tradersCountIsSet = ref<boolean>(false);
const allCount = ref<any>({});
const keyword = ref<string>("");
const loggedInUser = JSON.parse(localStorage.getItem("user")!);
const selectedTab = ref();
const isStatCountUpdated = ref(false);

export const DashboardComposables = (props?: any) => {
  filterList.value = [
    {
      name: "Followers",
      value: ValidSortFilter.followersCount,
    },
    {
      name: "Following",
      value: ValidSortFilter.followingCount,
    },
    {
      name: "Views",
      value: ValidSortFilter.viewsCount,
    },
    {
      name: "Diversification",
      value: ValidSortFilter.diversification,
    },
    // {
    //   name: "Loss",
    //   value: ValidSortFilter.loss,
    // },
    {
      name: "Gains",
      value: ValidSortFilter.gain,
    },
    {
      name: "Investment",
      value: ValidSortFilter.investments,
    },
    {
      name: "Trades",
      value: ValidSortFilter.trades,
    },
    {
      name: "Top Gain",
      value: ValidSortFilter.topGain,
    },
    // {
    //   name: "Top Loss",
    //   value: ValidSortFilter.topLoss,
    // },
    {
      name: "Average Gain",
      value: ValidSortFilter.avgGain,
    },
    // {
    //   name: "Average Loss",
    //   value: ValidSortFilter.avgLoss,
    // },
    {
      name: "Outreach",
      value: ValidSortFilter.outreach,
    },
  ];

  // var apiCallTimeout: any;

  const selectStats = async (data: any) => {
    // clearTimeout(apiCallTimeout);
    if (data.title == "All") {
      markets.value = [];
    } else {
      var ind = markets.value.findIndex((x) => x == data.title);
      if (ind == -1) {
        markets.value.push(data.title);
      } else {
        markets.value.splice(ind, 1);
      }
    }
    page.value = 1;
    addScrollEventListener();
    usersList.value = [];
    isDone.value = false;
    setTimeout(async () => {
      getFilteredUser();
    }, 3000);
    // apiCallTimeout = setTimeout((e: any) => {
    // }, 300);
  };

  const getFilteredUser = async () => {
    isUsersLoading.value = true;
    try {
      let query = null;
      query = `?type=${segmentType.value}&sortFilter=${filterType.value}&page=${
        page.value
      }&count=${count.value}&market=${markets.value.join(",")}&keyword=${
        keyword.value
      }`;
      return httpService
        .getFilteredUser(query)
        .then((response: any) => {
          isUsersLoading.value = false;
          // isUsersTwoLoading.value = false;
          if (response.data.isSuccess) {
            allCount.value = response.data.data.diversificationCount;
            for (let item of getTotalStats.value) {
              if (item.title === "All") {
                item.count = allCount.value.total;
              }
              if (item.title === StockMarkets.Futures) {
                item.count = allCount.value.futures;
              }
              if (item.title === StockMarkets.crypto) {
                item.count = allCount.value.crypto;
              }
              if (item.title === StockMarkets.etf) {
                item.count = allCount.value.ETFs;
              }
              if (item.title === StockMarkets.indices) {
                item.count = allCount.value.indices;
              }
              if (item.title === StockMarkets.nft) {
                item.count = allCount.value.nft;
              }
              if (item.title === StockMarkets.options) {
                item.count = allCount.value.options;
              }
              if (item.title === StockMarkets.stocks) {
                item.count = allCount.value.stocks;
              }
            }
            if (page.value == 1) {
              usersList.value = [];
            }
            if (usersList.value.length === 0) {
              usersList.value = response.data.data.list;
            } else {
              for (let item of response.data.data.list) {
                usersList.value.push(item);
              }
            }
            tradersCount.value = response.data.data.totalTraders;
            tradersCountIsSet.value = true
            totalCount.value = response.data.data.totalCount;
            if (page.value * count.value >= totalCount.value) {
              isDone.value = true;
            } else {
              page.value += 1;
            }
            return getTotalStats

          } else {
            allCount.value = {};
            totalCount.value = 0;
            // tradersCount.value = 0;
            // usersList.value = [];
            isDone.value = false;
            // for (let item of getTotalStats.value) {
            //   if (item.title === "All") {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.Futures) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.crypto) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.etf) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.indices) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.nft) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.options) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.stocks) {
            //     item.count = 0;
            //   }
            // }
          }
          isStatCountUpdated.value = true;
        })
        .catch((e: any) => {
          
          isUsersLoading.value = false;
          isStatCountUpdated.value = true;
        });
    } catch (e) {
      isStatCountUpdated.value = true;
    }
  };
  const myAllOtherUsers = async (id: string) => {
    isUsersLoading.value = true;
    try {
      let query = null;
      query = `?type=${segmentType.value}&sortFilter=${filterType.value}&page=${
        page.value
      }&count=${count.value}&market=${markets.value.join(",")}&keyword=${
        keyword.value
      }`;
      httpService
        .getAllUserOfOtherUser(query, id)
        .then((response: any) => {
          isUsersLoading.value = false;
          // isUsersTwoLoading.value = false;
          if (response.data.isSuccess) {
            allCount.value = response.data.data.diversificationCount;
            for (let item of getTotalStats.value) {
              if (item.title === "All") {
                item.count = allCount.value.total;
              }
              if (item.title === StockMarkets.Futures) {
                item.count = allCount.value.futures;
              }
              if (item.title === StockMarkets.crypto) {
                item.count = allCount.value.crypto;
              }
              if (item.title === StockMarkets.etf) {
                item.count = allCount.value.ETFs;
              }
              if (item.title === StockMarkets.indices) {
                item.count = allCount.value.indices;
              }
              if (item.title === StockMarkets.nft) {
                item.count = allCount.value.nft;
              }
              if (item.title === StockMarkets.options) {
                item.count = allCount.value.options;
              }
              if (item.title === StockMarkets.stocks) {
                item.count = allCount.value.stocks;
              }
            }
            if (page.value == 1) {
              usersList.value = [];
            }
            if (usersList.value.length === 0) {
              usersList.value = response.data.data.list;
            } else {
              for (let item of response.data.data.list) {
                usersList.value.push(item);
              }
            }
            tradersCount.value = response.data.data.totalTraders;
            tradersCountIsSet.value = true
            totalCount.value = response.data.data.totalCount;
            if (page.value * count.value >= totalCount.value) {
              isDone.value = true;
            } else {
              page.value += 1;
            }
          } else {
            allCount.value = {};
            totalCount.value = 0;
            // tradersCount.value = 0;
            // usersList.value = [];
            isDone.value = false;
            // for (let item of getTotalStats.value) {
            //   if (item.title === "All") {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.Futures) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.crypto) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.etf) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.indices) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.nft) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.options) {
            //     item.count = 0;
            //   }
            //   if (item.title === StockMarkets.stocks) {
            //     item.count = 0;
            //   }
            // }
          }
          isStatCountUpdated.value = true
        })
        .catch((e: any) => {
          isUsersLoading.value = false;
        });
    } catch (e) {}
  };

  const followerOfOtherUSer = async (id: string) => {
    isUsersLoading.value = true;
    try {
      let query = null;
      query = `?type=${segmentType.value}&sortFilter=${filterType.value}&page=${
        page.value
      }&count=${count.value}&market=${markets.value.join(",")}&keyword=${
        keyword.value
      }`;
      httpService
        .getOtherUserFollowedUser(query, id)
        .then((response: any) => {
          isUsersLoading.value = false;
          // isUsersTwoLoading.value = false;
          if (response.data.isSuccess) {
            allCount.value = response.data.data.diversificationCount;
            for (let item of getTotalStats.value) {
              if (item.title === "All") {
                item.count = allCount.value.total;
              }
              if (item.title === StockMarkets.Futures) {
                item.count = allCount.value.futures;
              }
              if (item.title === StockMarkets.crypto) {
                item.count = allCount.value.crypto;
              }
              if (item.title === StockMarkets.etf) {
                item.count = allCount.value.ETFs;
              }
              if (item.title === StockMarkets.indices) {
                item.count = allCount.value.indices;
              }
              if (item.title === StockMarkets.nft) {
                item.count = allCount.value.nft;
              }
              if (item.title === StockMarkets.options) {
                item.count = allCount.value.options;
              }
              if (item.title === StockMarkets.stocks) {
                item.count = allCount.value.stocks;
              }
            }
            if (page.value == 1) {
              usersList.value = [];
            }
            if (usersList.value.length === 0) {
              usersList.value = response.data.data.list;
            } else {
              for (let item of response.data.data.list) {
                usersList.value.push(item);
              }
            }
            tradersCount.value = response.data.data.totalTraders;
            tradersCountIsSet.value = true
            totalCount.value = response.data.data.totalCount;
            if (page.value * count.value >= totalCount.value) {
              isDone.value = true;
            } else {
              page.value += 1;
            }
          } else {
            allCount.value = {};
            totalCount.value = 0;
            isDone.value = false;
          }
          isStatCountUpdated.value = true
        })
        .catch((e: any) => {
          isUsersLoading.value = false;
        });
    } catch (e) {}
  };
  const followingOfOtherUSer = async (id: string) => {
    isUsersLoading.value = true;
    try {
      let query = null;
      query = `?type=${segmentType.value}&sortFilter=${filterType.value}&page=${
        page.value
      }&count=${count.value}&market=${markets.value.join(",")}&keyword=${
        keyword.value
      }`;
      httpService
        .getOtherUserFollowingUser(query, id)
        .then((response: any) => {
          isUsersLoading.value = false;
          if (response.data.isSuccess) {
            allCount.value = response.data.data.diversificationCount;
            for (let item of getTotalStats.value) {
              if (item.title === "All") {
                item.count = allCount.value.total;
              }
              if (item.title === StockMarkets.Futures) {
                item.count = allCount.value.futures;
              }
              if (item.title === StockMarkets.crypto) {
                item.count = allCount.value.crypto;
              }
              if (item.title === StockMarkets.etf) {
                item.count = allCount.value.ETFs;
              }
              if (item.title === StockMarkets.indices) {
                item.count = allCount.value.indices;
              }
              if (item.title === StockMarkets.nft) {
                item.count = allCount.value.nft;
              }
              if (item.title === StockMarkets.options) {
                item.count = allCount.value.options;
              }
              if (item.title === StockMarkets.stocks) {
                item.count = allCount.value.stocks;
              }
            }
            if (page.value == 1) {
              usersList.value = [];
            }
            if (usersList.value.length === 0) {
              usersList.value = response.data.data.list;
            } else {
              for (let item of response.data.data.list) {
                usersList.value.push(item);
              }
            }
            tradersCount.value = response.data.data.totalTraders;
            tradersCountIsSet.value = true
            totalCount.value = response.data.data.totalCount;
            if (page.value * count.value >= totalCount.value) {
              isDone.value = true;
            } else {
              page.value += 1;
            }
          } else {
            allCount.value = {};
            totalCount.value = 0;
            isDone.value = false;
          }
          isStatCountUpdated.value = true
        })
        .catch((e: any) => {
          isUsersLoading.value = false;
          isStatCountUpdated.value = true
        });
    } catch (e) {}
  };

  const getData = async () => {
    if (
      props &&
      props.user &&
      props.user._id &&
      loggedInUser &&
      loggedInUser._id &&
      props.user._id === loggedInUser?._id
    ) {

      await getFilteredUser();
    } else {
      if (selectedTab.value) {
        if (selectedTab.value.type === "follower") {
          await followerOfOtherUSer(props?.user?._id);
        } else if (selectedTab.value.type === "following") {
          await followingOfOtherUSer(props?.user?._id);
        } else {
          await myAllOtherUsers(props?.user?._id);
        }
      }
    }
  };

  const debouncedGetFilteredUser = debounce(getFilteredUser, 1000);
  const onScroll = () => {
    if (isDone.value) {
      removeScrollEventListener();
      return;
    }
    const frameView = document.getElementById("frame_view");
    if (frameView) {
      const bottomOffset = 100; // Offset to trigger the next load, can be adjusted
      if (
        frameView.scrollHeight - frameView.scrollTop <=
          frameView.clientHeight + bottomOffset &&
        !isUsersLoading.value
      ) {
        debouncedGetFilteredUser();
      }
    }
  };

  onMounted(() => {
    addScrollEventListener();
  });
  const addScrollEventListener = () => {
    const frameView = document.getElementById("frame_view");
    if (frameView) {
      frameView.addEventListener("scroll", onScroll);
    }
  };
  const removeScrollEventListener = () => {
    const frameView = document.getElementById("frame_view");
    if (frameView) {
      frameView.removeEventListener("scroll", onScroll);
    }
  };

  onBeforeUnmount(() => {
    removeScrollEventListener();
  });
  // const getDashboardFilterCountData = async () => {
  //   try {
  //     httpService
  //       .getDashboardFilterCountData()
  //       .then((response: any) => {
  //         if (response.data.isSuccess) {
  //           allCount.value = response.data.data;
  //           for (let item of getTotalStats.value) {
  //             if (item.title === "All") {
  //               item.count = allCount.value.total;
  //             }
  //             if (item.title === StockMarkets.Futures) {
  //               item.count = allCount.value.futures;
  //             }
  //             if (item.title === StockMarkets.crypto) {
  //               item.count = allCount.value.crypto;
  //             }
  //             if (item.title === StockMarkets.etf) {
  //               item.count = allCount.value.ETFs;
  //             }
  //             if (item.title === StockMarkets.indices) {
  //               item.count = allCount.value.indices;
  //             }
  //             if (item.title === StockMarkets.nft) {
  //               item.count = allCount.value.nft;
  //             }
  //             if (item.title === StockMarkets.options) {
  //               item.count = allCount.value.options;
  //             }
  //             if (item.title === StockMarkets.stocks) {
  //               item.count = allCount.value.stocks;
  //             }
  //           }
  //         } else {
  //         }
  //       })
  //       .catch((e: any) => { });
  //   } catch (e) { }
  // };

  const changingTabs = async (data: any) => {
    // clearTimeout(apiCallTimeout);
    if (data != "all" && storageService.getUser().isGuestUser) {
      utilService.showGuestUserFlow();
      return;
    }
    segmentType.value = data;
    usersList.value = [];
    setTimeout(async () => {
      await getData();
    }, 3000);
    // apiCallTimeout = setTimeout(async (e: any) => {
    // }, 300);
  };

  const selectFilter = async (data: any) => {
    // clearTimeout(apiCallTimeout);
    if (storageService.getUser().isGuestUser) {
      utilService.showGuestUserFlow();
      return;
    }
    filterType.value = data.value;
    filterName.value = data.name;
    page.value = 1;
    usersList.value = [];
    isDone.value = false;
    addScrollEventListener();
    setTimeout(async () => {
      await getData();
    }, 3000);
    // apiCallTimeout = setTimeout(async (e: any) => {
    // }, 300);
  };

  const showMore = () => {
    // page.value = page.value + 1;
    getData();
  };

  const getTotalStats = ref([
    {
      title: "All",
      count: 0,
      color:
        "linear-gradient(128deg, #FFC972 0%, #FF8C69 55%, #8D45DF 100%) 0% 0% no-repeat padding-box",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.stocks,
      count: 0,
      color: "#934BFF",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.crypto,
      count: 0,
      color: "#FF851B",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.etf,
      count: 0,
      color: "#598AFF",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.Futures,
      count: 0,
      color: "#39D3D3",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.nft,
      count: 0,
      color: "#FF3B3B",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.indices,
      count: 0,
      color: "#FFF01B",
      maxWidth: 0,
      content: "",
    },
    {
      title: StockMarkets.options,
      count: 0,
      color: "#FF1B9C",
      maxWidth: 0,
      content: "",
    },
  ]);

  return {
    filterList,
    selectStats,
    changingTabs,
    segmentType,
    showMore,
    page,
    // getDashboardFilterCountData,
    count,
    filterName,
    selectFilter,
    isUsersLoading,
    // isUsersTwoLoading,
    markets,
    usersList,
    getFilteredUser,
    filterType,
    totalCount,
    getTotalStats,
    tradersCount,
    tradersCountIsSet,
    isDone,
    keyword,
    selectedTab,
    getData,
    myAllOtherUsers,
    followingOfOtherUSer,
    followerOfOtherUSer,
    isStatCountUpdated
  };
};
