import router from "@/router/router";
import PhoneNumberModel from "../models/phonenumber.model";
import httpService from "@/services/http.service";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { ref } from "vue";
import { TYPE } from "vue-toastification";
import User from "@/helpers/models/user.model";

const profileUser = ref<User | any>(new User());
const profilePicture = ref("");
const coverPhoto = ref("");

export const userProfileUser = () => {
  const updateProfileUser = async (user: User) => {
    if (user.isBlocked) {
      utilService.showToast("You cannot view profile you blocked", TYPE.ERROR);
      router.push({ name: "blocked_users" });
      return;
    }
    profileUser.value = user;
    if (!profileUser.value.shortBio) {
      profileUser.value.shortBio = "";
    }
    if (!profileUser.value.phoneNumber) {
      profileUser.value.phoneNumber = new PhoneNumberModel();
    }
    if (!profileUser.value.phoneNumber.countryCode) {
      profileUser.value.phoneNumber.countryCode = "";
    }
    if (!profileUser.value.phoneNumber.cellNumber) {
      profileUser.value.phoneNumber.cellNumber = "";
    }
    profilePicture.value = updateProfilePicture();
    coverPhoto.value = updateCoverPhoto();
  };

  const updateProfilePicture = () =>
    utilService.getUserProfilePicture(
      profileUser.value!._id!,
      profileUser.value!.profilePicture,
      profileUser.value!.gender!
    );
  const updateCoverPhoto = () =>
    utilService.getUserProfilePicture(
      profileUser.value!._id!,
      profileUser.value!.coverPhoto,
      profileUser.value!.gender!,
      true
    );

  const updateUser = async () => {
    try {
      const response = await httpService.updateUser(profileUser.value);
      if (response.isSuccess) {
        storageService.setUser(response.data);
        utilService.showToast("Profile Updated Successfully.");
      }
    } catch (e) {}
  };

  return {
    updateUser,
    profileUser,
    profilePicture,
    coverPhoto,
    updateProfileUser,
    updateCoverPhoto,
  };
};
