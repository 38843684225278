import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import { StockSocketTransferDTO } from "@/helpers/models/stock/stock_socket_transfer.dto";
import utilService from "@/services/util.service";
import { Socket, io } from "socket.io-client";
import { onMounted } from "vue";

let socket: Socket;

var stockIds: StockSocketTransferDTO[] = [];
var stocksData: any = {};

export const useStockSocket = () => {
  var timeout: any;


  const debounceOnInput = (func: any, ms: number = 1000) => {
    clearTimeout(timeout);
    timeout = setTimeout((e: any) => func(e), ms);
  };

  const connectToSocket = () => {
    socket = io(utilService.getLiveHostURL());
    socket.on("connect", () => {
      listenStockFromStockIds();
    });
  };

  const disconnectFromSocket = () => {
    socket.removeAllListeners();
    socket.close();
  };

  var count = 1;
  const listenStockFromStockIds = () => {
    if (socket.connected && !document.hidden) {
      if (stockIds.length) {
        count++;
        socket.emit(
          "getstocksdata",
          JSON.stringify({ stocks: stockIds }),
          (data: any) => {
            if (data && data.length) {
              for (let stock of data) {
                if (stock && stock.stockId) {
                  stocksData[stock.stockId] = stock;
                }
              }
            }
          }
        );
      }
    }
    setTimeout(() => {
      listenStockFromStockIds();
    }, 3000);
  };

  const addStockId = (stockObj: StockSocketTransferDTO) => {
    if (stockObj) {
      checkStockExistence(stockObj.stockId!, stockObj.showTrendChart);
    }
  };

  const clearStockIds = () => {
    stockIds = [];
  };

  const getDataByStockId = (stockId: string) => {
    if (stocksData && stocksData[stockId]) {
      return stocksData[stockId];
    }
    return null;
  };

  const checkStockExistence = (stockId: string, showChart: boolean = false) => {
    if (stockId) {
      var index = stockIds.findIndex((x) => x.stockId == stockId);
      if (index == -1) {
        stockIds.push(new StockSocketTransferDTO(stockId, showChart));
      } else if (stockIds[index].showTrendChart != showChart) {
        stockIds[index].showTrendChart = showChart;
      }
      return true;
    }
    return null;
  };

  const searchStocks = (keyword: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        socket.emit("searchStockByKeyword", keyword, (data: SocketStock[]) => {
          if (!data) {
            data = [];
          }
          resolve(data.slice(0, 40));
        });
      } catch (e) {
        resolve([]);
      }
    });
  };

  return {
    socket,
    debounceOnInput,
    stockIds,
    searchStocks,
    connectToSocket,
    disconnectFromSocket,
    addStockId,
    clearStockIds,
    getDataByStockId,
    checkStockExistence,
  };
};
