import { SectionType } from "@/composables/section/models/section.model";

export enum SettingConfigurationStatus {
  followers = "followers",
  public = "public",
  friends = "friends",
}

export class UserProfileConfiguration {
  widgets: UserSettingConfiguration[] = [
    new UserSettingConfiguration(
      "Show Profile",
      "showProfile",
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Top Statistics",
      SectionType.topStats,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Attributes",
      SectionType.traderType,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Social Profiles",
      "socialLinks",
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Badges",
      "badges",
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "About",
      "about",
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Investments",
      SectionType.favouriteInvestment,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Winning Trades",
      SectionType.winningTrades,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Portfolio",
      SectionType.portfolio,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Recommendations",
      SectionType.recommendation,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Watchlist",
      SectionType.watchlists,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Favourite Influencer",
      SectionType.favouriteInfluencer,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Points",
      SectionType.points,
      SettingConfigurationStatus.public,
    ),
    new UserSettingConfiguration(
      "Links",
      SectionType.links,
      SettingConfigurationStatus.public,
    ),
  ];
}

export class UserSettingConfiguration {
  name: string | undefined;
  type: string | undefined;
  status: SettingConfigurationStatus | undefined;

  constructor(
    name: string,
    type: string,
    status: SettingConfigurationStatus,
  ) {
    this.name = name;
    this.type = type;
    this.status = status;
  }
}
