export default class SocialLinks {
    category: string | undefined;
    platform: string | undefined;
    value: string | undefined;
    icon: string | undefined;
    stat: string | undefined;
    isConnected?: boolean | undefined;
    isStatsEnabled?: boolean | undefined;
    isAvailable?: boolean | undefined;

    public static initialArray = [
        {
            platform: "facebook",
            category: "social",
            value: "",
            stat: "",
            icon: "icon-facebook.svg",
        },
        {
            platform: "twitter",
            category: "social",
            stat: "",
            value: "",
            icon: "icon-twitter.svg",
        },
        {
            platform: "instagram",
            category: "social",
            stat: "",
            value: "",
            icon: "icon-instagram.svg",
        },
        {
            platform: "linkedin",
            category: "social",
            stat: "",
            value: "",
            icon: "icon-linkedin.svg",
        },
        {
            platform: "youtube",
            category: "streaming",
            stat: "",
            value: "",
            icon: "icon-youtube.svg",
        },
        {
            platform: "discord",
            category: "chatting",
            stat: "",
            value: "",
            icon: "icon-discord.svg",
        },
        {
            platform: "twitch",
            category: "chatting",
            stat: "",
            value: "",
            icon: "icon-twitch.svg",
        },
        {
            platform: "telegram",
            category: "chatting",
            stat: "",
            value: "",
            icon: "icon-telegram.svg",
        },
    ];

    public static initializeArrayWithData(socialLink: any) {
        var arr : SocialLinks[] = this.initialArray;
        for(let item of arr){
            if(socialLink[item.platform!]){
                item.value = socialLink[item.platform!]
            }
        }
        return arr;
    }
}