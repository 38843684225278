<template>
  <span class="svg_icon">
    <inline-svg :src="require('../assets/svg' + path + '/' + icon)" />
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
    },
    path: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss">
.svg_icon {
  width: 25px;
  height: 25px;
  display: inline-block !important;
  fill: var(--color-white);
  svg{height: 100%;width: 100%;display: block;}
  &.topAligned{
    svg{vertical-align: top;}
  }
  &.s-white{
    stroke: var(--color-white);
  }
  &.f-none{fill: var(--color-black)}
}
</style>
