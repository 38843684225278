import storageService from "@/services/storage.service"

const routerGuard = (router: any) =>
{

    // router.beforeEach((to: any, from: any, next: any) =>
    // {
    //     if (to.matched.some((record: any) => record.meta.authScreen))
    //     {
    //         const token = storageService.getToken();
    //         if (!token)
    //         {
    //             next()
    //             return true;
    //         }
    //         next(false);
    //         return false;
    //     } else if (to.matched.some((record: any) => record.meta.requiresAuth))
    //     {
    //         const token = storageService.getToken();
    //         if (token)
    //         {
    //             next()
    //             return true;
    //         }
    //         next('/signin')
    //         return false;
    //     }
    //     next()
    // })
}

export default routerGuard;