import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3a231c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sparkline_container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_ctx.series)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_apexchart, {
          type: _ctx.chartType,
          height: "100%",
          width: "100%",
          ref: "chart",
          options: _ctx.chartOptions,
          series: _ctx.series
        }, null, 8, ["type", "options", "series"])
      ]))
    : _createCommentVNode("", true)
}