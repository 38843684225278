class ThemeService {
  currentTheme: TraderverseThemes = TraderverseThemes.traderverse;

  changeTheme(theme: TraderverseThemes) {
    document.body.classList.remove(this.currentTheme);
    this.currentTheme = theme;
    document.body.classList.add(this.currentTheme);
  }
}

export enum TraderverseThemes {
  traderverse = "traderverse",
  light = "light",
  dark = "dark",
}

export default new ThemeService();
