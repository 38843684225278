export class PlayBooks {
  title: string | undefined;
  timestamp: number | undefined;
  stocks: PlayBooksModel[] = [];
  strategy: boolean | undefined;
  _id: string | undefined;
  constructor(
    title: string | undefined,
    timestamp: number | undefined,
    strategy: boolean | undefined
  ) {
    this.title = title;
    this.timestamp = timestamp;
    this.strategy = strategy;
  }
}

export class PlayBooksModel {
  stockId: string | undefined;
  symbol: string | undefined;
  type: string | undefined;
  companyName: string | undefined;
  logoUrl: string | undefined;
  price: number = 0;
  change: number | undefined;
  changesPercentage: number | undefined;
  notes: string | undefined;
  exchange: string | undefined;
  traderType: string | undefined;
  targetOne: number = 0;
  targetTwo: number = 0;
  entry: number  = 0;
  stopLoss: number = 0;

  constructor(
    stockId: string,
    symbol: string,
    type: string,
    companyName: string,
    logoUrl: string,
    price: number = 0,
    change: number,
    changesPercentage: number,
    notes: string,
    exchange: string,
    traderType: string,
    targetOne: number = 0,
    targetTwo: number = 0,
    entry: number = 0,
    stopLoss: number = 0
  ) {
    this.stockId = stockId;
    this.symbol = symbol;
    this.type = type;
    this.logoUrl = logoUrl;
    this.companyName = companyName;
    this.price = price;
    this.traderType = traderType;
    this.exchange = exchange;
    this.notes = notes;
    this.changesPercentage = changesPercentage;
    this.change = change;
    this.targetOne = targetOne;
    this.targetTwo = targetTwo;
    this.entry = entry;
    this.stopLoss = stopLoss;
  }
}



export class PreviewPlayBooks {
  title: any = '-';
  timestamp: any = '-';
  stocks: PlayBooksModel[] = [];
  strategy: boolean | undefined;
  _id: string | undefined;
}