import firebaseService from "@/services/firebase.service";
import storageService from "@/services/storage.service";
import axios from "axios";
import axiosRetry from "axios-retry";

const baseURL = "/api";

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosRetry(instance, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: async (error) => {
    // if retry condition is not specified, by default idempotent requests are retried
    const originalConfig = error.config;
    let user = storageService.getUser();
    if (originalConfig.url !== "/auth/signin" && error.response && user.uid) {
      if (error.response.status == 401 && originalConfig) {
        // request for a new token\
        await firebaseService.getAuthToken();
        // update the error config with new token
        // error.config.__isRetryRequest = true;
        let firebaseToken = storageService.getToken();
        if (firebaseToken.length > 0) {
          error.config.headers!.token = `Firebase ${firebaseToken}`;
          // instance(error.config);
          return true;
        }
        return false;
      }
    }
    return error.response!.status === 503;
  },
});

export default instance;
