<template>
  <div class="qrCodeSection" v-if="user">
    <div class="qrCodeHead">
      <figure v-if="images.coverPhoto && images.coverPhoto !== ''">
        <img
          :src="images.coverPhoto"
          @error="(event) => (event.srcElement.src = imageError())"
        />
      </figure>

      <figure
        v-if="
          !images.coverPhoto || (images.coverPhoto && images.coverPhoto == '')
        "
      >
        <img
          crossorigin="anonymous"
          src="../../../../assets/images/dummy-cover.png"
        />
      </figure>
    </div>
    <div class="qrCodeBody">
      <div class="body">
        <div class="ProfileDetailDv">
          <div class="firstDv">
            <template v-if="allLeftStats && allLeftStats.length">
              <TopStatItemComponent
                v-for="item in allLeftStats.slice(0, 1)"
                class="showShort"
                :key="item"
                :stat="item"
              />
            </template>
          </div>
          <div class="middleDv">
            <div class="profileiconinfoDv">
              <QRAvatarComponent
                v-if="images.profilePicture && images.profilePicture !== ''"
                :key="images.profilePicture"
                :profileImage="images.profilePicture"
                :border="true"
                :badges="false"
                class="large"
                :showQRCode="false"
              />
            </div>
          </div>
          <div class="firstDv justify-content-end">
            <TopStatItemComponent
              v-for="item in allRightStats.slice(0, 1)"
              :key="item"
              class="showShort"
              :stat="item"
            />
          </div>
        </div>
        <div class="ProfiletexinfoDv">
          <h2>{{ user.fullName }}</h2>
          <p>@{{ user.userName }}</p>
        </div>
        <div class="spacingInfoDv">
          <InvestmentBarComponent
            :fromqrcodescreen="true"
            :stocks="favouriteInvestmentStocks"
            v-if="favouriteInvestmentStocks && favouriteInvestmentStocks.length"
          />
        </div>
        <div class="scanQrCodeDv">
          <template v-if="qrCodeImage && qrCodeImage != ''">
            <img crossorigin="anonymous" :src="qrCodeImage" />
            <IconComponent icon="icon-new-log.svg" />
          </template>
        </div>
        <div class="profileLink">
          <a>Profile Link</a>
        </div>
        <div class="itemInfo" v-if="user">
          <p>Personal info as VCard</p>
          <el-switch @change="getQrCode()" v-model="generateVcard" />
        </div>
        <div class="itemInfo" v-if="user">
          <p>Pre approved Friend Request</p>
          <el-switch @change="getQrCode()" v-model="sendFollowRequest" />
        </div>
      </div>
      <div class="foot">
        <p>
          Share your QR with your friends, so they can add you as a contact.
        </p>
        <a @click="resetQRCode()">Reset QR Code</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import IconComponent from "@/components/IconComponent.vue";
import QRAvatarComponent from "./QRAvatarComponent.vue";
import { SectionType } from "@/composables/section/models/section.model";
import { useSectionComposable } from "@/composables/section/section.composable";
import httpService from "@/services/http.service";
import storageService from "@/services/storage.service";
import utilService from "@/services/util.service";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import InvestmentBarComponent from "../../profile-screen/components/investment-bar/InvestmentBarComponent.vue";
import { useProfileScreenComposable } from "../../profile-screen/composables/profile.screen.composable";
import TopStatItemComponent from "../../profile-screen/components/TopStatsComponent/TopStatItemComponent.vue";
const QRCode = require("qrcode");

export default defineComponent({
  components: {
    IconComponent,
    QRAvatarComponent,
    TopStatItemComponent,
    InvestmentBarComponent,
  },
  setup() {
    const user = ref<any>({});
    const favouriteInvestmentStocks = ref<any[]>([]);
    const isQrCodeLoading = ref<boolean>(false);
    const sendFollowRequest = ref<boolean>(false);
    const generateVcard = ref<boolean>(false);
    const qrCodeImage = ref("");
    const images = ref<any>({
      profilePicture: "",
      coverPhoto: "",
    });
    const { allLeftStats, allRightStats } = useProfileScreenComposable();

    const { sections, getSectionRefByType } = useSectionComposable();
    onMounted(async () => {
      let info = storageService.getUser();
      if (info) {
        httpService
          .getUserById(info._id!)
          .then((res) => {
            if (res.isSuccess) {
              storageService.setUser(res.data);
              user.value = storageService.getUser();
              getQrCode();
              images.value.profilePicture = utilService.getUserProfilePicture(
                user.value?._id!,
                user.value?.profilePicture!,
                "male",
                false,
                false
              );
              images.value.coverPhoto = utilService.getUserProfilePicture(
                user.value?._id!,
                user.value?.coverPhoto!,
                "male",
                true,
                false
              );
              if (sections.value.length) {
                var fvtInvst = sections.value.find(
                  (x) => x.type == SectionType.favouriteInvestment
                );
                if (fvtInvst && fvtInvst.data && fvtInvst.data.stocks) {
                  favouriteInvestmentStocks.value = fvtInvst.data.stocks;
                }
              }
            }
          })
          .catch((e) => {});
      }
    });

    watch(storageService.getUser(), () => {
      user.value = storageService.getUser();
    });

    const getQrCode = async () => {
      isQrCodeLoading.value = true;
      try {
        qrCodeImage.value = "";
        user.value.qrcode.generateVcard = generateVcard.value;
        user.value.qrcode.sendFollowRequest = sendFollowRequest.value;
        let obj = {
          generateVcard: generateVcard.value,
          sendFollowRequest: sendFollowRequest.value,
        };
        storageService.setUser(user.value);
        await httpService
          .getQrCode(obj)
          .then(async (response) => {
            if (
              response.isSuccess &&
              response.data &&
              response.data.qrcodeData
            ) {
              qrCodeImage.value = await QRCode.toDataURL(
                response.data.qrcodeData
              );
            }
          })
          .catch((e) => {});
      } catch (e) {}
      isQrCodeLoading.value = false;
    };

    const resetQRCode = () => {
      generateVcard.value = false;
      sendFollowRequest.value = false;
      getQrCode();
    };

    const imageError = () => utilService.coverImageError();

    return {
      resetQRCode,
      user,
      imageError,
      qrCodeImage,
      allLeftStats,
      allRightStats,
      getQrCode,
      generateVcard,
      images,
      favouriteInvestmentStocks,
      sendFollowRequest,
    };
  },
});
</script>
<style lang="scss" scoped>
@import "../QRCode.scss";
</style>
