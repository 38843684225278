import filters from "@/helpers/filters";

export class SocketStock {
    _id: string | undefined;
    stockId: string | undefined;
    symbol: string | undefined;
    name: string | undefined;
    price: number | undefined;
    changesPercentage: number | undefined;
    change: number | undefined;
    dayLow: number | undefined;
    dayHigh: number | undefined;
    yearHigh: number | undefined;
    yearLow: number | undefined;
    marketCap: number | undefined;
    priceAvg50: number | undefined;
    priceAvg200: number | undefined;
    exchange: string | undefined;
    volume: number | undefined;
    avgVolume: number | undefined;
    open: number | undefined;
    previousClose: number | undefined;
    eps: number | undefined;
    pe: number | undefined;
    earningsAnnouncement: string | undefined;
    sharesOutstanding: number | undefined;
    timestamp: number | undefined;
    fiveDayTrend: any[] | undefined;
    key: string;
    type: string | undefined;
    logoUrl: string | undefined;
    isSelected: boolean = false;
    docId: number | undefined;
    constructor(
        stockId: string,
        symbol: string,
        companyName: string,
        price: number,
        change: number,
        changePercent: number,
        logoUrl: string | undefined = undefined,
    ) {
        this.key = new Date().getTime().toString();
        this._id = stockId;
        this.stockId = stockId;
        this.symbol = filters.validateStockSymbol(symbol);
        this.name = companyName;
        this.price = price;
        this.change = change;
        this.changesPercentage = changePercent;
        if (!this.change) {
            this.change = changePercent;
        }
        if (!this.changesPercentage) {
            this.changesPercentage = change;
        }
        if (logoUrl && logoUrl.includes('cdn-images')) {
            this.logoUrl = logoUrl;
        }
    }

    static convertToStockObject(destinaton: SocketStock | undefined, obj: SocketStock): SocketStock {
        if (!destinaton) {
            destinaton = new SocketStock(
                obj._id!,
                filters.validateStockSymbol(obj.symbol!),
                obj.name!,
                obj.price!,
                obj.change!,
                obj.changesPercentage!,
                obj.logoUrl!,
            );
        }
        if (destinaton._id != obj._id) destinaton._id = obj._id;
        if (destinaton.stockId != obj.stockId) destinaton.stockId = obj.stockId;
        if (filters.validateStockSymbol(destinaton.symbol!) != filters.validateStockSymbol(obj.symbol!)) destinaton.symbol = filters.validateStockSymbol(obj.symbol!);
        if (destinaton.name != obj.name) destinaton.name = obj.name;
        if (destinaton.price != obj.price) destinaton.price = obj.price;
        if (destinaton.logoUrl != obj.logoUrl) destinaton.logoUrl = obj.logoUrl;
        if (destinaton.change != obj.change) destinaton.change = obj.change;
        if (destinaton.type != obj.type) destinaton.type = obj.type;
        if (destinaton.changesPercentage != obj.changesPercentage) destinaton.changesPercentage = obj.changesPercentage;
        if (obj.type && obj.type.toLowerCase() == 'crypto') {
            destinaton._id = `${filters.validateStockSymbol(destinaton.symbol!)}_CRYPTO`;
        }
        if(!destinaton._id){
            destinaton._id = destinaton.stockId;
        }
        return destinaton;
    }

    static copy(destinaton: SocketStock, obj: SocketStock): SocketStock {
        if (!destinaton) {
            destinaton = new SocketStock(
                obj._id!,
                filters.validateStockSymbol(obj.symbol!),
                obj.name!,
                obj.price!,
                obj.change!,
                obj.changesPercentage!
            );
        }
        if (!destinaton._id) {
            destinaton._id = obj._id;
        }
        if (filters.validateStockSymbol(destinaton.symbol!) != filters.validateStockSymbol(obj.symbol!)) {
            destinaton.symbol = filters.validateStockSymbol(obj.symbol!);
        }
        if (destinaton.name != obj.name) {
            destinaton.name = obj.name;
        }
        if (destinaton.price != obj.price) {
            destinaton.price = obj.price;
        }
        if (destinaton.change != obj.change) {
            destinaton.change = obj.change;
        }
        if (destinaton.logoUrl != obj.logoUrl) {
            destinaton.logoUrl = obj.logoUrl;
        }
        if (destinaton.changesPercentage != obj.changesPercentage) {
            destinaton.changesPercentage = obj.changesPercentage;
        }
        if (destinaton.avgVolume != obj.avgVolume) {
            destinaton.avgVolume = obj.avgVolume;
        }
        if (destinaton.previousClose != obj.previousClose) {
            destinaton.previousClose = obj.previousClose;
        }
        if (obj.fiveDayTrend && obj.fiveDayTrend.length) {
            var lastValue: number | undefined = undefined;
            if (destinaton.fiveDayTrend && destinaton.fiveDayTrend.length) {
                if (destinaton.fiveDayTrend![0].data.length) {
                    lastValue = destinaton.fiveDayTrend![0].data[destinaton.fiveDayTrend![0].data.length - 1];
                }
            }
            var newValue = obj.fiveDayTrend![0].data[obj.fiveDayTrend![0].data.length - 1];
            if (lastValue == undefined || newValue != lastValue) {
                destinaton.key = new Date().getTime().toString();
                destinaton.fiveDayTrend = obj.fiveDayTrend;
            }
        }

        return destinaton;
    }

}