<template>
  <div
    class="app-modal modal modal-fullscreen viewFullScreeninfoDv"
    v-if="playbookInfo && ShowModalBit"
  >
    <div class="app-modal--head flexRow">
      <!-- <div class="back-btn-container"></div> -->
      <IconComponent
        @click="dismiss()"
        class="back-btn back-btn-container"
        icon="icon-chevron-left.svg"
      />
      <h6>{{ playbookInfo.title }}</h6>
      <a
        class="add-btn has-bg"
        @click="openPlayBookForm()"
        v-if="currentUserId == userID"
      >
        <IconComponent
          path="/Profile_Tabs"
          class="addIcon"
          icon="Edit-Profile.svg"
        />
        Edit
      </a>
      <a class="add-btn" v-else></a>
    </div>
    <div class="app-modal--body">
      <el-scrollbar class="verticalScroller newScrollbar native" :native="true">
        <div class="modal_list_container newPlaybookViewGridDv">
          <template v-for="item in playbookInfo.stocks" :key="item">
            <ViewPlayBookCardItem
              :class="{
                shortActive: item.traderType == 'short',
                longActive: item.traderType == 'long',
              }"
              :stock="item"
            />
          </template>
        </div>
      </el-scrollbar>
    </div>
    <div class="app-modal--footer">
      <div class="app-modal--footer-buttons">
        <button class="btn btn--outline" @click="dismiss()">Close</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import ViewPlayBookCardItem from "./ViewPlayBookCardItem.vue";
import IconComponent from "@/components/IconComponent.vue";
import { popModal, pushModal } from "jenesius-vue-modal";
import PlaybookFormComponent from "./playbook-form/PlaybookForm.vue";
import { useSectionComposable } from "@/composables/section/section.composable";
import { SectionType } from "@/composables/section/models/section.model";
import { useProfileScreenComposable } from "../../composables/profile.screen.composable";

export default defineComponent({
  props: {
    playBookData: Object,
    currentUserId: String,
    userID: String,
  },
  components: {
    IconComponent,
    ViewPlayBookCardItem,
  },
  setup(props) {
    const dismiss = () => popModal();
    const playbookInfo = ref<any>();
    const ShowModalBit = ref<boolean>(true);

    onMounted(() => {
      if (props.playBookData) {
        playbookInfo.value = props.playBookData;
        if (props.currentUserId != props.userID) {
        }
      }
    });

    const { getSectionRefByType, updateSection } = useSectionComposable();
    const { updateProfileSection } = useProfileScreenComposable();

    const openPlayBookForm = async (ind: number = -1) => {
      if (props.playBookData) {
        var trade: any | undefined = undefined;
        trade = props.playBookData;
        ShowModalBit.value = false;
        var res = await pushModal(PlaybookFormComponent, {
          existingPlaybook: trade,
        });
        res.on("formCompleted", (list: any) => {
          ShowModalBit.value = true;
          playbookInfo.value = list;
          var section = getSectionRefByType(SectionType.playbook);
          if (section && section.data && section.data.playbook) {
            var info = section.data.playbook.findIndex(
              (x: any) => x._id == list._id
            );
            if (info != -1) {
              section.data.playbook[info] = list;
            }
            updateProfileSection(section);
          }
        });
        res.onclose = () => {
          ShowModalBit.value = true;
        };
      }
    };

    return { dismiss, openPlayBookForm, playbookInfo, ShowModalBit };
  },
});
</script>
<style lang="scss" scoped>
@import "./ViewPlayBookComponent.scss";
</style>
