<template>
  <div
    class="stockItemCard"
    :class="{ removeable: showRemoveIcon }"
    @click="goToStockDetail"
  >
    <template v-if="stock">
      <IconComponent
        class="iconDv"
        icon="minus.svg"
        :id="`minusStock${stock.stockId}`"
        v-if="showRemoveIcon"
        @click.stop="onRemove()"
      />
      <StockImageTag
        :stockId="stock._id"
        :imgUrl="stock.logoUrl"
        :symbol="stock.symbol"
        :market="stock.type"
        :disableRouting="disableRouting"
      />
      <div class="textDv">
        <div class="headinginfoDv">
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="stockData.symbol"
            placement="top-start"
            v-if="isOverflowing"
          >
            <h1 ref="symbolElement">
              {{ stockData.symbol }}
            </h1>
          </el-tooltip>
          <h1 v-else ref="symbolElement">
            {{ stockData.symbol }}
          </h1>
          <span
            v-if="!fromSearchStock"
            :class="{ downward: stock.changesPercentage < 0 }"
          >
            <IconComponent class="priceIcon" icon="stock-pricing-up-icon.svg" />
            {{
              $filters.systemNumberConvention(
                stock.changesPercentage,
                false,
                false
              )
            }}%</span
          >
        </div>
        <p v-if="fromSearchStock">
          {{ stock.name }}
        </p>
        <p
          ref="priceElement"
          v-html="
            $filters.systemNumberConvention(stock.price, true, false, 110)
          "
        ></p>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { useSocketStockData } from "@/composables/stock_data.composable";
import { SocketStock } from "@/helpers/models/stock/socket_stock.model";
import { RouteEnum } from "@/router/routeNames";
import router from "@/router/router";
import { popModal } from "jenesius-vue-modal";
import { defineComponent, nextTick, onMounted, ref, watch } from "vue";

export default defineComponent({
  props: {
    stockData: SocketStock,
    disableRouting: Boolean,
    showRemoveIcon: {
      type: Boolean,
      default: false,
    },
    fromSearchStock: Boolean,
  },
  setup(props, context) {
    const priceElement = ref<HTMLElement | null>(null);
    const widthCalculating = ref();

    const { stockData: stock, updateStockInfoObject } = useSocketStockData(
      props.stockData
    );

    const isOverflowing = ref(false);
    const symbolElement = ref<HTMLElement | null>(null);
    const checkOverflow = () => {
      nextTick(() => {
        if (symbolElement.value) {
          isOverflowing.value =
            symbolElement.value.scrollWidth > symbolElement.value.clientWidth;
        }
      });
    };

    onMounted(() => {
      checkOverflow();
    });

    watch(symbolElement, (newValue, oldValue) => {
      if (newValue) {
        checkOverflow();
      }
    });

    watch(
      () => props.stockData?.symbol,
      () => {
        checkOverflow();
      },
      { immediate: true }
    );

    onMounted(() => {
      if (priceElement.value) {
        const width = priceElement.value.offsetWidth;
        widthCalculating.value = width;
      }
    });

    watch(props, () => {
      updateStockInfoObject(props.stockData);
    });

    watch(
      () => priceElement,
      () => {
        if (priceElement.value) {
          const width = priceElement.value.offsetWidth;
          widthCalculating.value = width;
        }
      }
    );

    const onRemove = () => context.emit("onRemove");

    const goToStockDetail = (ev: any) => {
      if (!props.disableRouting) {
        try {
          ev.stopPropagation();
        } catch (e) {}
        popModal();
        if (stock.value && stock.value?._id) {
          router.push({
            name: RouteEnum.stockDetail,
            params: { stockId: stock.value._id },
          });
        }
      }
    };

    return {
      goToStockDetail,
      onRemove,
      stock,
      priceElement,
      widthCalculating,
      isOverflowing,
      symbolElement,
      checkOverflow,
    };
  },
});
</script>
<style lang="scss" scoped>
.stockItemCard {
  width: var(--stock-item-widget-width);
  flex: 0 0 var(--stock-item-widget-width);
  max-width: var(--stock-item-widget-width);
  box-sizing: border-box;
  background: #2c2743 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5019607843);
  gap: var(--tile-horizontal-gap);
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(0deg, #25203c, #25203c),
    linear-gradient(
      102.28deg,
      rgba(37, 32, 60, 0.2) 51.56%,
      rgba(52, 37, 126, 0.2) 100%
    );
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5019607843);
  cursor: pointer;
  &.removeable {
    .textDv {
      .headinginfoDv {
        span {
          padding-right: 3px;
        }
      }
    }
  }
  .iconDv {
    position: absolute;
    top: 0px;
    cursor: pointer;
    right: -10px;
    width: 18px;

    height: auto;
    z-index: 99999;
  }

  .textDv {
    width: 100%;
    overflow: hidden;
    .headinginfoDv {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0px 0px 5px;
      gap: 2px;
      h1 {
        flex-grow: 1;
        @include stockSymbol();
        margin: 0px;
        font-size: var(--font-2md);
        color: var(--color-label);
      }
      span {
        @include changePercent();
        height: 12px;
        line-height: 1.3;
      }
    }
    p {
      @include stockPrice();
      margin-right: 0px;
      color: var(--text-color);
      font-size: var(--font-2md);
      font-weight: 600;
    }
  }
}

@media screen and (max-width: 767px) {
  .stockItemCard {
    width: var(--stock-item-widget-width);
    flex: 0 0 var(--stock-item-widget-width);
    max-width: var(--stock-item-widget-width);
  }
}
</style>
